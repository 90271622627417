// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

// callout variant

.widget.-callout {
    & {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 0;
        margin: remify(0 0 33);
        padding-bottom: (390 / 570) * 100%;
        position: relative;
        width: 100%;
    }

    .widget_link {
        & {
            bottom: 0;
            display: block;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .widget_title {
        color: $primary_alt;
        font-size: remify(27.5, 16);
        left: 0;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;

        @supports (transform: translateX(-50%) translateY(-50%)) {
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: auto;
        }
    }
}
