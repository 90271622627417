// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero-block {
    & {
        @include layer("hero");
    }
}

.hero_inner {
    & {
        background: $background;
        margin: 0 auto;
        width: remify($site-width);
        max-width: 100%;
        padding: remify(($content-padding * 1.25) $content-padding);
        position: relative;
    }
}

.hero_title {
    & {
        color: mix($primary, darken(saturate(adjust-hue($primary, 0.9870), 0.5495), 25.4902));
        font-size: remify(27.5, 16);
        margin-bottom: remify(9, 27.5);
        text-shadow: remify(0 3 7, 27.5) transparentize($dark, 0.65);
        text-transform: uppercase;
    }

    @supports (background-clip: text) or (-webkit-background-clip: text) {
        background: linear-gradient(to bottom, $primary 25%, darken(saturate(adjust-hue($primary, 0.9870), 0.5495), 25.4902) 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color:transparent;
    }
}

// fullbleed variant

.hero-block.-fullbleed {
    & {
        background: $background;
        background-size: cover;
        padding: remify(($content-padding * 0.833333333333333) $content-padding);
        position: relative;
    }

    > .hero_inner {
        background: none;
        padding: 0;
    }

    > .hero_inner.-fullbleed {
        max-width: none;
    }
}
