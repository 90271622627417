// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
        background: $primary_alt;
        margin-top: auto;
    }
}

.footer_inner {
    & {
        background: $primary_alt;
        margin: 0 auto;
        max-width: remify(1050);
        padding: remify(($content-padding * 2) $content-padding ($content-padding * 4) $content-padding);
    }
}

.footer_link {
    & {
        color: $accent_alt;
    }

    &:focus,
    &:hover {
        color: $accent;
    }
}

.footer_title {
    & {
        color: $light;
        font-size: remify(22.49, 16);
        margin-bottom: remify(7.496666666666667, 22.49);
        text-align: center;
    }
}

.footer_text {
    & {
        color: $light;
        font-size: remify(15, 16);
        line-height: remify(17.245, 15);
        text-align: center;
        margin-bottom: remify(30, 15);
    }
}

.footer_logo {
    & {
        margin: 0 auto;
        width: remify(129, 16);
    }
}

// fullbleed variant

.footer-block.-fullbleed {
    & {
        background: $primary_alt;
        padding: remify(($content-padding * 1.25) $content-padding);
    }

    > .footer_inner {
        background: none;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }
}

.footer_row > [class*="col-"] {
    margin-bottom: remify(40);
}
