// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Input
\* ------------------------------------------------------------------------ */

.input {
    & {
        background: $background;
        border-radius: 0;
        border: remify(1) solid darken($background_alt, 10);
        color: $foreground;
        display: block;
        font-family: $body-font;
        font-size: remify(13.405, 16);
        font-weight: 400;
        line-height: remify(17.255, 13.405);
        margin: remify(0 0 10, 13.405);
        outline: 0;
        padding: remify(7 8 5, 13.405);
        transition: border-color 0.15s, color 0.15s;
        width: 100%;
        /* remove weird user agent styles */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    &:focus,
    &:hover {
        border-color: $accent;
    }
}

// Tablet variant

@media screen and (min-width: $xs-break) {
    .input {
        font-size: remify(17.9, 16);
    }
}

// select variant

.input.-select {
    & {
        cursor: pointer;
        // remove Firefox default styles & arrow
        text-indent: 0.01px;
        text-overflow: "";
        // remove IE default styles & arrow
        &::-ms-expand {display: none;}
    }

    &.-arrow {
        /* bgImage: ignore */
        background: url('data:image/svg+xml;utf8,<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1408 1088q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45zm0-384q0 26-19 45t-45 19h-896q-26 0-45-19t-19-45 19-45l448-448q19-19 45-19t45 19l448 448q19 19 19 45z"/></svg>');
        background-position: center right remify(8, 12);
        background-repeat: no-repeat;
        background-size: remify(12 12, 12);
        padding-right: remify(30, 12);
    }
}

// checkbox & radiobutton variants

.input.-checkbox,
.input.-radiobutton {
    & {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: remify(10, 12);
        cursor: pointer;
        display: inline-block;
        height: remify(16, 12);
        padding: 0;
        vertical-align: middle;
        width: remify(16, 12);
        // remove styles where possible
        -webkit-appearance: none;
        appearance: none;
    }

    &.-radiobutton {
        border-radius: 50%;
    }

    &.-checkbox:checked {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewbox='0 0 32 32'%3E%3Cpath d='M32,8.5c0,0.5-0.2,1-0.6,1.4L16.5,24.9l-2.8,2.8c-0.4,0.4-0.9,0.6-1.4,0.6c-0.5,0-1-0.2-1.4-0.6l-2.8-2.8l-7.5-7.5,C0.2,17,0,16.6,0,16c0-0.6,0.2-1,0.6-1.4l2.8-2.8c0.4-0.4,0.9-0.6,1.4-0.6c0.5,0,1,0.2,1.4,0.6l6.1,6.1L25.8,4.3,c0.4-0.4,0.9-0.6,1.4-0.6c0.6,0,1,0.2,1.4,0.6l2.8,2.8C31.8,7.5,32,8,32,8.5z' /%3E%3C/svg%3E");
    }

    &.-radiobutton:checked {
        /* bgImage: ignore */
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewbox='0 0 32 32'%3E%3Cpath d='M29.9,8c1.4,2.5,2.1,5.1,2.1,8s-0.7,5.6-2.1,8s-3.4,4.4-5.8,5.8S18.9,32,16,32c-2.9,0-5.6-0.7-8-2.1S3.6,26.5,2.1,24,C0.7,21.6,0,18.9,0,16s0.7-5.6,2.1-8C3.6,5.5,5.5,3.6,8,2.1S13.1,0,16,0c2.9,0,5.6,0.7,8,2.1S28.4,5.5,29.9,8z' /%3E%3C/svg%3E");
    }

    & + .text.-label {
        display: inline-block;
    }
}

// touch checkbox & radiobutton variants

@media (pointer: coarse) {
    .input.-checkbox,
    .input.-radiobutton {
        & {
            font-size: remify(24, 16);
        }
    }
}
