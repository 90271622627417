// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * CTA
\* ------------------------------------------------------------------------ */

.cta {
    & {
        background: $primary;
        padding: remify(($content-padding * 1.25) $content-padding);
    }
}

.cta_link {
    & {
        color: $light;
    }

    &:focus,
    &:hover {
        color: $light_alt;
    }
}

.cta_text {
    & {
        color: $light;
        font-size: remify(14, 16);
        margin: remify(8 0, 14);
        text-transform: uppercase;
    }

    // not a fan of doing this, but it's easier

    @media screen and (max-width: $xs-break) {
        ._big {
            display: block;
            font-size: remify(16, 12) !important;
        }
    }
}
