// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Toolbar
\* ------------------------------------------------------------------------ */

.toolbar-block {
    & {
        @include layer("toolbar");
    }
}

.toolbar_inner {
    & {
        background: $background_alt;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.5) ($content-padding * 0.666666666666667));
    }
}

// fullbleed varaint

.toolbar-block.-fullbleed {
    & {
        background: $background_alt;
        padding: remify(($content-padding * 0.5) ($content-padding * 0.666666666666667));
    }

    > .toolbar_inner {
        background: none;
        padding: 0;
    }

    > .toolbar_inner.-fullbleed {
        max-width: none;
    }
}
