// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * User Content
\* ------------------------------------------------------------------------ */

.user-content {
    & {
        @extend %clearfix;
    }

    // links

    a:not(.button) {
        color: $warning;
        text-decoration: none;
        transition: color 0.15s;
    }

    a:not(.button):hover {
        color: $warning_alt;
    }

    // titles

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: darken($foreground, 22.3529);
        font-family: $heading-font;
        font-weight: 900;
    }

    h1,
    h2 {
        font-size: remify(23.005, 16);
        letter-spacing: remify(tracking(13, 23.005), 23.005);
        line-height: remify(28.75625, 23.005);
        margin: remify(0 0 10, 23.005);
    }

    h3 {
        font-size: remify(16.775, 16);
        font-weight: 500;
        line-height: remify(16.775, 16.775);
        margin: 0;
        text-transform: uppercase;
    }

    h4 {
        font-size: remify(15.0975, 16);
        font-weight: 500;
        line-height: remify(19.2996375, 15.0975);
        margin: 0;
    }

    h5,
    h6 {
        font-size: remify(16.666666666666667, 16);
        font-weight: 600;
        line-height: remify(23.111111111111111, 16.666666666666667);
        margin: remify(0 0 11.666666666666667, 16.666666666666667);
    }

    // text

    p,
    ol,
    ul,
    table {
        color: $dark;
        font-family: $body-font;
        font-size: remify(15, 16);
        font-weight: 400;
        line-height: remify(20, 15);
        margin: remify(0 0 25.218846153846154, 15);
    }

    ol {
        padding-left: remify(18, 13.405);
    }

    ul {
        color: darken($dark, 22.3529);
        font-size: remify(16.75625, 16);
        list-style: none;
        padding-left: 0;
    }

    ul li {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23962520' d='M29.9,8c1.4,2.5,2.1,5.1,2.1,8s-0.7,5.6-2.1,8s-3.4,4.4-5.8,5.8S18.9,32,16,32c-2.9,0-5.6-0.7-8-2.1S3.6,26.5,2.1,24C0.7,21.6,0,18.9,0,16s0.7-5.6,2.1-8C3.6,5.5,5.5,3.6,8,2.1S13.1,0,16,0c2.9,0,5.6,0.7,8,2.1S28.4,5.5,29.9,8z M26.8,12.6c0-0.4-0.1-0.7-0.4-1l-1.9-1.9c-0.3-0.3-0.6-0.4-0.9-0.4s-0.7,0.1-0.9,0.4l-8.5,8.5l-4.7-4.7c-0.3-0.3-0.6-0.4-0.9-0.4s-0.7,0.1-0.9,0.4l-1.9,1.9c-0.2,0.2-0.4,0.6-0.4,1c0,0.4,0.1,0.7,0.4,0.9l7.5,7.5c0.3,0.3,0.6,0.4,0.9,0.4c0.4,0,0.7-0.1,1-0.4l11.3-11.3C26.6,13.3,26.8,13,26.8,12.6z' /%3E%3C/svg%3E%0A");
        background-position: left remify(2.5, 16.75625);
        background-repeat: no-repeat;
        background-size: remify(15.080625, 16.75625);
        padding-left: remify(25.134375, 16.75625);
    }

    ul li:not(:last-child) {
        margin-bottom: remify(4.1890625, 16.75625);
    }

    ol ol,
    ol ul,
    ul ol,
    ul ul {
        font-size: 1em;
    }

    .table_container {
        overflow: auto;
        width: 100%;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    table td,
    table th {
        border: remify(1) solid darken($background_alt, 10);
        padding: remify(6 8, 13.405);
        text-align: left;
        vertical-align: top;
    }

    table tbody th,
    table > tr > th,
    table[data-stacked="true"] tbody td:before {
        font-weight: 700;
        text-transform: uppercase;
    }

    table thead td,
    table thead th {
        background: $dark_alt;
        border-color: lighten($dark_alt, 10);
        color: $light;
        font-size: remify(16, 13.405);
        font-weight: 400;
        padding: remify(6 8, 16);
    }

    table > tr:nth-child(even) td,
    table > tr:nth-child(even) th,
    table tbody tr:nth-child(even) td,
    table tbody tr:nth-child(even) th {
        background: $background_alt;
    }

    // scrolling responisve tables

    @media screen and (max-width: $xs-break - (1 / 16)) {
        table {
            display: block;
            overflow: auto;
            padding-bottom: 1px;
        }
    }

    // blockquote

    blockquote {
        font-style: italic;
        margin: remify(0 0 18, 16);
        padding: remify(0 0 0 28, 16);
        position: relative;
    }

    blockquote p,
    blockquote ol,
    blockquote ul,
    blockquote table {
        color: lighten($dark, 50);
        font-size: remify(14, 16);
    }

    blockquote > :last-child {
        margin-bottom: 0;
    }

    // horizontal rule

    hr {
        background: darken($background_alt, 10);
        border: 0;
        clear: both;
        height: remify(1);
        margin: remify(0 0 20, 16);
        width: 100%;
    }

    // images

    img {
        display: block;
        height: auto !important;
        max-width: 100%;
    }

    // alignment

    .alignleft {
        float: left;
        margin-right: remify(25, 16);
        max-width: (100% / 3);
    }

    .alignright {
        float: right;
        margin-left: remify(25, 16);
        max-width: (100% / 3);
    }

    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

// Tablet variant

@media screen and (min-width: $xs-break) {
    .user-content {
        h1,
        h2 {
            font-size: remify(30.68, 16);
        }

        h3 {
            font-size: remify(20, 16);
        }

        h4 {
            font-size: remify(18, 16);
        }

        h5,
        h6 {
            font-size: remify(20, 16);
        }

        p,
        ol,
        ul,
        table {
            font-size: remify(17, 16);
            line-height: remify(23, 17);
        }

        ol.columns,
        ul.columns {
            columns: 3;
        }

        ol.columns li,
        ul.columns li {
            break-inside: avoid;
        }

        ul {
            font-size: remify(20, 16);
        }

        blockquote {
            margin-bottom: remify(24, 16);
            padding-left: remify(36, 16);
        }

        blockquote:before {
            font-size: remify(24, 16);
        }

        blockquote p,
        blockquote ol,
        blockquote ul,
        blockquote table {
            font-size: remify(18, 16);
        }
    }
}

// light variant

.user-content.-light {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    table,
    ul {
        color: $light;
    }
}
