// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }
}

.header_inner {
    & {
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.833333333333333) $content-padding);
    }
}

.header_link {
    & {
        color: $accent;
    }

    &:focus,
    &:hover {
        color: $accent_alt;
    }
}

.header_title {
    & {
        color: $primary;
        font-size: remify(17, 16);
        text-transform: uppercase;
    }
}

.header_logo {
    & {
        margin: remify(0 auto);
        width: remify(165, 16);
    }
}

.header_menu-list_container {
    .menu-list.-navigation {
        margin-top: remify(12, 17.23);
        transform: translateX(#{remify(24, 17.23)});
    }

    .menu-list.-social {
        font-size: remify(25, 16);
        margin: remify(0 0 0 25, 25);
    }

    .menu-list.-social .menu-list_link {
        color: $primary;
    }

    .menu-list.-social .menu-list_link:focus,
    .menu-list.-social .menu-list_link:hover {
        color: $warning;
    }
}

// fullbleed varaint

.header-block.-fullbleed {
    & {
        background: $light;
        border-bottom: remify(9) solid $accent;
        padding: 0;
    }

    > .header_inner {
        background: none;
        border-bottom: 0;
    }

    > .header_inner.-fullbleed {
        max-width: none;
    }
}
